export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const BaseAlbumScroll = () => import('../../components/base/BaseAlbumScroll.vue' /* webpackChunkName: "components/base-album-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCustomTelInput = () => import('../../components/base/BaseCustomTelInput.vue' /* webpackChunkName: "components/base-custom-tel-input" */).then(c => wrapFunctional(c.default || c))
export const BaseImagesSection = () => import('../../components/base/BaseImagesSection.vue' /* webpackChunkName: "components/base-images-section" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseLanguageSwitcher = () => import('../../components/base/BaseLanguageSwitcher.vue' /* webpackChunkName: "components/base-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const BaseMap = () => import('../../components/base/BaseMap.vue' /* webpackChunkName: "components/base-map" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseNumbersCenter = () => import('../../components/base/BaseNumbersCenter.vue' /* webpackChunkName: "components/base-numbers-center" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/BaseSelect.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSponsorScroll = () => import('../../components/base/BaseSponsorScroll.vue' /* webpackChunkName: "components/base-sponsor-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseStoreIcons = () => import('../../components/base/BaseStoreIcons.vue' /* webpackChunkName: "components/base-store-icons" */).then(c => wrapFunctional(c.default || c))
export const BaseTeamMembers = () => import('../../components/base/BaseTeamMembers.vue' /* webpackChunkName: "components/base-team-members" */).then(c => wrapFunctional(c.default || c))
export const BaseTextArea = () => import('../../components/base/BaseTextArea.vue' /* webpackChunkName: "components/base-text-area" */).then(c => wrapFunctional(c.default || c))
export const BaseTextSection = () => import('../../components/base/BaseTextSection.vue' /* webpackChunkName: "components/base-text-section" */).then(c => wrapFunctional(c.default || c))
export const BaseValuesBox = () => import('../../components/base/BaseValuesBox.vue' /* webpackChunkName: "components/base-values-box" */).then(c => wrapFunctional(c.default || c))
export const BugPageAdvantages = () => import('../../components/bugPage/BugPageAdvantages.vue' /* webpackChunkName: "components/bug-page-advantages" */).then(c => wrapFunctional(c.default || c))
export const BugPageDetailsBlack = () => import('../../components/bugPage/BugPageDetailsBlack.vue' /* webpackChunkName: "components/bug-page-details-black" */).then(c => wrapFunctional(c.default || c))
export const BugPageForm = () => import('../../components/bugPage/BugPageForm.vue' /* webpackChunkName: "components/bug-page-form" */).then(c => wrapFunctional(c.default || c))
export const CareersRemote = () => import('../../components/careers/CareersRemote.vue' /* webpackChunkName: "components/careers-remote" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const CarouselReviewCarousel = () => import('../../components/carousel/ReviewCarousel.vue' /* webpackChunkName: "components/carousel-review-carousel" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/copyright/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FeaturesAi = () => import('../../components/features/FeaturesAi.vue' /* webpackChunkName: "components/features-ai" */).then(c => wrapFunctional(c.default || c))
export const FeaturesArtists = () => import('../../components/features/FeaturesArtists.vue' /* webpackChunkName: "components/features-artists" */).then(c => wrapFunctional(c.default || c))
export const FeaturesBilboard = () => import('../../components/features/FeaturesBilboard.vue' /* webpackChunkName: "components/features-bilboard" */).then(c => wrapFunctional(c.default || c))
export const FeaturesDownload = () => import('../../components/features/FeaturesDownload.vue' /* webpackChunkName: "components/features-download" */).then(c => wrapFunctional(c.default || c))
export const FeaturesIcons = () => import('../../components/features/FeaturesIcons.vue' /* webpackChunkName: "components/features-icons" */).then(c => wrapFunctional(c.default || c))
export const FeaturesSongtastic = () => import('../../components/features/FeaturesSongtastic.vue' /* webpackChunkName: "components/features-songtastic" */).then(c => wrapFunctional(c.default || c))
export const FeaturesVideo = () => import('../../components/features/FeaturesVideo.vue' /* webpackChunkName: "components/features-video" */).then(c => wrapFunctional(c.default || c))
export const FooterContent = () => import('../../components/footer/FooterContent.vue' /* webpackChunkName: "components/footer-content" */).then(c => wrapFunctional(c.default || c))
export const FooterIcons = () => import('../../components/footer/FooterIcons.vue' /* webpackChunkName: "components/footer-icons" */).then(c => wrapFunctional(c.default || c))
export const GetInTouchCongratulationBlock = () => import('../../components/getInTouch/CongratulationBlock.vue' /* webpackChunkName: "components/get-in-touch-congratulation-block" */).then(c => wrapFunctional(c.default || c))
export const GetInTouchLeftSide = () => import('../../components/getInTouch/LeftSide.vue' /* webpackChunkName: "components/get-in-touch-left-side" */).then(c => wrapFunctional(c.default || c))
export const GetInTouchRightSide = () => import('../../components/getInTouch/RightSide.vue' /* webpackChunkName: "components/get-in-touch-right-side" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/home/HomeBanner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const HomeCarousel = () => import('../../components/home/HomeCarousel.vue' /* webpackChunkName: "components/home-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeIcons = () => import('../../components/home/HomeIcons.vue' /* webpackChunkName: "components/home-icons" */).then(c => wrapFunctional(c.default || c))
export const HomeIdContent = () => import('../../components/home/HomeIdContent.vue' /* webpackChunkName: "components/home-id-content" */).then(c => wrapFunctional(c.default || c))
export const HomeIdSocial = () => import('../../components/home/HomeIdSocial.vue' /* webpackChunkName: "components/home-id-social" */).then(c => wrapFunctional(c.default || c))
export const HomeLeft = () => import('../../components/home/HomeLeft.vue' /* webpackChunkName: "components/home-left" */).then(c => wrapFunctional(c.default || c))
export const HomeRight = () => import('../../components/home/HomeRight.vue' /* webpackChunkName: "components/home-right" */).then(c => wrapFunctional(c.default || c))
export const HomeVideo = () => import('../../components/home/HomeVideo.vue' /* webpackChunkName: "components/home-video" */).then(c => wrapFunctional(c.default || c))
export const NavigationMobileNavigation = () => import('../../components/navigation/MobileNavigation.vue' /* webpackChunkName: "components/navigation-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const PartnersForm = () => import('../../components/partners/PartnersForm.vue' /* webpackChunkName: "components/partners-form" */).then(c => wrapFunctional(c.default || c))
export const PartnersIcons = () => import('../../components/partners/PartnersIcons.vue' /* webpackChunkName: "components/partners-icons" */).then(c => wrapFunctional(c.default || c))
export const PartnersOldPage = () => import('../../components/partners/PartnersOldPage.vue' /* webpackChunkName: "components/partners-old-page" */).then(c => wrapFunctional(c.default || c))
export const PartnersPhoneText = () => import('../../components/partners/PartnersPhoneText.vue' /* webpackChunkName: "components/partners-phone-text" */).then(c => wrapFunctional(c.default || c))
export const PartnersPhones = () => import('../../components/partners/PartnersPhones.vue' /* webpackChunkName: "components/partners-phones" */).then(c => wrapFunctional(c.default || c))
export const PartnersUsPage = () => import('../../components/partners/PartnersUsPage.vue' /* webpackChunkName: "components/partners-us-page" */).then(c => wrapFunctional(c.default || c))
export const PartnersUsBenefits = () => import('../../components/partners/partnersUs/partnersUsBenefits.vue' /* webpackChunkName: "components/partners-us-benefits" */).then(c => wrapFunctional(c.default || c))
export const PartnersUsHeader = () => import('../../components/partners/partnersUs/partnersUsHeader.vue' /* webpackChunkName: "components/partners-us-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
