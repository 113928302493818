export default {
  home: 'home',
  location: 'Wajib diisi.',
  description: 'Silakan masukkan deskripsi',
  explanation: 'Silakan masukkan penjelasan.',
  firstName: 'Silakan masukkan nama depan.',
  lastName: 'Silakan masukkan nama belakang.',
  email: 'Silakan masukkan alamat email yang valid.',
  country: 'Silakan masukkan negara.',
  signature: 'Silakan masukkan nama lengkap Anda.',
  qr_text: 'Pindai untuk Mengunduh aplikasi TREBEL',
  features: 'features',
  support_your_artists: "Tanpa bayar sepeserpun, bisa dengerin lebih dari 70 juta lagu dari seluruh dunia.",
  support_description: "Tanpa bayar sepeserpun, bisa dengerin lebih dari 70 juta lagu dari seluruh dunia.",
  enjoy_music: "Nikmati musik favoritmu",
  enjoy_music_description: "Temukan musik dari label-label rekaman terbesar di dunia dan distributor-distributor musik independen besar.",
  sponcor_experience: "Patrocina la experiencia de TREBEL",
  sponcor_btn: "DESCUBRE CÓMO",
  get_full_experience: "obtenga la experiencia completa",
  take_control_of_music: "¡TOMA EL CONTROL DE TU MÚSICA!",
  pass_gives_benefits: "TREBEL PASS te da los beneficios de una experiencia musical de lujo sin amarrarte a una suscripción.",
  improve_experience: "Mejora tu experiencia y disfruta aún más de tu música favorita",
  with_pass: "Con el Pase TREBEL podrás disfrutar de:",
  choose_your_pass: "Elige tu pass",
  payment_method: 'Método de pago',
  no_ads: "Sin anuncios",
  speed_downloads: "DESCARGAS SÚPER RÁPIDAS",
  all_your_playlists: 'Todas tus playlistst',
  listen_while_downloading: "SIN INTERRUPCIONES",
  unlimited_coins: "Monedas ilimitadas",
  how_it_works: "Así funciona",
  days: "días",
  phone_number: "Número de teléfono",
  email: "E-mail",
  personal_data:
    "Sus datos personales se utilizarán para procesar su pedido, respaldar su experiencia en este sitio web y para otros fines descritos en nuestra política de privacidad.",
  download_the_app: 'Download App',
  meta: {
    main: {
      title: "Aplikasi pengunduh musik gratis untuk Android dan iOS | TREBEL Music",
      description: "Dengarkan lebih dari 70 juta lagu favorit gratis selamanya, resmi dan dengerin tanpa interenet!"
    },
    about: {
      title: "Kepoin TREBEL Music yuk!",
      description: "Cuma TREBEL yang bisa kasih kamu dengerin musik gratis selamanya di Android & iOs. Kok bisa? Yuk kepoin TREBEL lebih lanjut di sini!"
    },
    career: {
      title: "Mau kerja di TREBEL Music?",
      description: "Kami butuh kamu untuk bantu seluruh penikmat musik di Indonesia bisa dengerin musik tanpa harus bayar lagi dengan TREBEL. Cek lowongannya di sini!"
    },
    partners: {
      title: "Gabung jadi mitra TREBEL dan ketemu jutaan pengguna aplikasinya yuk!",
      description: "Bikin produk kamu tepat sasaran ke jutaan pengguna aplikasi TREBEL Music di seluruh dunia."
    }
  },
  streaks: {
    title: `Bagaimana musik dapat meningkatkan suasana hati, kesehatan, dan kecerdasanmu`,
    march5: "MARTE 5, 2024",
    minRead: "BACAAN 5 MENIT",
    wellness: "KESEJAHTERAAN",
    content: {
      intro: `Pernahkah Anda bertanya-tanya mengapa lagu favorit Anda memiliki dampak yang begitu kuat pada suasana hati Anda? Ilmu pengetahuan memiliki jawabannya! Singkatnya, mendengarkan musik setiap hari bukan hanya rutinitas, tetapi juga dosis kesehatan, pendorong semangat, bahkan penyemangat otak.`,
      intro2: `Berbicara tentang penguat, dengan mendengarkan musik setiap hari di TREBEL, Anda dapat mendapatkan penguat untuk mengunduh lagu Anda lebih cepat.`,
      qrTitle: 'Scanlah QR dengan teleponmu dan mulailah streak.',
      qrButton: 'Mulai sebuah streak!'
    },
    list: {
      title: "Ilmu pengetahuan mendukungnya.",
      subtitle: "Pada tahun 2009, arkeolog menemukan seruling yang berusia 40.000 tahun, merupakan alat musik tertua yang diketahui, menunjukkan bahwa hubungan kita dengan musik memiliki akar yang dalam. Namun, bagaimana musik memengaruhi kita saat ini?",
      elements: {
        first: {
          title: '1. Musik menghubungkan kita.',
          sub1: "Pernahkah Anda merasakan koneksi istimewa itu dalam sebuah konser? Itu sungguh nyata! Musik menghubungkan kita secara sosial, mulai dari himne di acara olahraga hingga mengirimkan lagu cinta kepada pasangan Anda.",
          sub2: `Bahkan, beberapa ilmuwan evolusi mengusulkan bahwa ini bermula dari masa ketika nenek moyang kita hidup di pohon dan "menyanyikan" untuk membentuk ikatan sosial dan membentuk suku mereka.`
        },
        second: {
          title: "2. Musik membuat kita lebih bahagia.",
          sub1: "Musik dapat mengubah kimia otak Anda: menyebabkan perasaan senang, mengurangi stres, dan mempromosikan hubungan. Ini juga merupakan alat yang sangat baik untuk mengatur emosi dan memproses perasaan.",
          sub2: "Apakah Anda merasa cemas? Studi menunjukkan bahwa musik dapat menenangkan saraf, membantu kita untuk berkonsentrasi, dan hidup dalam momen tersebut."
        },
        third: {
          title: "3. Musik adalah bahan bakar untuk tubuh Anda.",
          sub1: "Musik membuat jantung Anda berdetak lebih kencang, untuk kebaikan! Ini dapat mengubah denyut jantung, tekanan darah, dan frekuensi pernapasan Anda. Jika Anda pergi ke gym, mendengarkan daftar lagu favorit Anda meningkatkan suasana hati, efisiensi, dan ketahanan Anda.",
          sub2: "Selain itu, menari dengan lagu favorit Anda? Itu adalah sesi kardio yang menyenangkan!"
        },
        forth: {
          title: "4. Musik adalah makanan untuk otak Anda.",
          sub1: "Ingin meningkatkan daya pikir Anda? Dokter di Johns Hopkins mengatakan bahwa mendengarkan musik bisa melakukannya, dengan mengaktifkan area otak yang terlihat dalam pemindaian resonansi magnetik.",
          sub2: "Melodi klasik tidak hanya membuat Anda merasa elegan, tetapi juga meningkatkan ingatan. Mereka membantu mengingat kata-kata dan menyelesaikan tugas dengan lebih cepat. Tidak suka musik klasik? Cari lagu dengan drum dan perkusi untuk mendapatkan efek yang sama."
        }
      }
    },
    takeaway: {
      title: "Dalam ringkasannya",
      sub1: "Dalam ringkasan, musik adalah kekuatan super yang meningkatkan ingatan, memperbaiki suasana hati, mengurangi kecemasan dan depresi, melawan kelelahan, meningkatkan respons terhadap rasa sakit, dan meningkatkan latihan Anda.",
      sub2: "Jika Anda ingin memaksimalkan manfaat-manfaat ini, berkomitmenlah untuk menyelesaikan streak di TREBEL. Tidak hanya Anda akan mendapatkan manfaat intrinsik dari mendengarkan musik setiap hari, tetapi Anda juga akan membuka hadiah seperti penguat untuk mempercepat unduhan Anda."
    }
  }
}
