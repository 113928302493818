export default {
  meta_title: "TREBEL: Descarga música sin costo y escúchala sin internet",
  home: "inicio",
  location: 'Obligatorio',
  description: 'Por favor, ingresa una descripción.',
  explanation: 'Por favor, ingresa una explicación.',
  firstName: 'Por favor, ingresa un nombre.',
  lastName: 'Por favor, ingresa un apellido.',
  email: 'Por favor, ingresa una dirección de correo electrónico válida',
  country: 'Por favor, ingresa un país.',
  signature: 'Por favor, ingresa tu nombre completo.',
  careers: 'empleos',
  aboutUs: 'nuestra-historia',
  partners: 'aliados',
  qr_text: 'Escanear para descargar la aplicación TREBEL',
  support_your_artists: "Apoya a tus artistas",
  support_description: "Compensamos justamente a los artistas y trabajamos con las disqueras más grandes del mundo para traerte su música de manera legal.",
  enjoy_music: "Disfruta tu música favorita",
  enjoy_music_description: "Encuentra la música de las disqueras más grandes del mundo y de las principales distribuidoras independientes.",
  sponsor_experience: "Patrocina la experiencia de TREBEL",
  sponsor_btn: "DESCUBRE CÓMO",
  get_full_experience: "obtenga la experiencia completa",
  take_control_of_music: "¡TOMA EL CONTROL DE TU MÚSICA!",
  pass_gives_benefits: "TREBEL PASS te da los beneficios de una experiencia musical de lujo sin amarrarte a una suscripción.",
  improve_experience: "Mejora tu experiencia y disfruta aún más de tu música favorita",
  with_pass: "Con el Pase TREBEL podrás disfrutar de:",
  choose_your_pass: "Elige tu pass",
  payment_method: 'Método de pago',
  no_ads: "Sin anuncios",
  speed_downloads: "DESCARGAS SÚPER RÁPIDAS",
  all_your_playlists: 'Todas tus playlistst',
  listen_while_downloading: "SIN INTERRUPCIONES",
  unlimited_coins: "Monedas ilimitadas",
  how_it_works: "Así funciona",
  days: "días",
  phone_number: "Número de teléfono",
  email: "E-mail",
  personal_data:
    "Sus datos personales se utilizarán para procesar su pedido, respaldar su experiencia en este sitio web y para otros fines descritos en nuestra política de privacidad.",
  download_the_app: 'Descarga la app',
  about_us: {
    main_title: 'Estamos cambiando la melodía por completo.',
    description_title: 'Somos Algo Completamente Nuevo.',
    description_1: "TREBEL se lanzó a finales de 2018 con la misión de crear una experiencia musical excepcional para millones de personas en todo el mundo. Los usuarios han adoptado rápidamente nuestro servicio, confirmando nuestra creencia de que diversos factores socioeconómicos (como lo son el ingreso, acceso a una tarjeta de crédito, el costo de la conectividad de datos, etc.) hacen que los servicios de suscripción sean inaccesibles para la mayoría de las personas alrededor del mundo.",
    description_2: 'Hoy en día, casi el 90% de los 6,500 millones de usuarios de teléfonos inteligentes que hay alrededor del mundo no pagan por la música que consumen. A medida que este número crece, creemos que la próxima gran oportunidad dentro de la industria de la música vendrá de mejorar la experiencia y monetización de estos miles de millones de usuarios musicales.',
    description_3: "En TREBEL, nos hemos asociado con los anunciantes globales más importantes y con compañías de música y medios internacionales para crear el primer servicio legal y con licencias que ofrece música sin necesidad de conexión a internet, todo gracias al patrocinio de las marcas.",
    download_and_listen: 'Descárgala y comienza a escuchar tu música a tu manera.',
    find_out: "Descarga la app"
  },
  map_description: "Nuestro equipo trabaja desde Mexico, Armenia, Indonesia, Colombia, Brasil y Estados Unidos para traer música al mundo entero.",
  our_team: "Nuestro equipo",
  board_members: "Miembros del consejo de administración",
  board_advisors: "Asesores del consejo de administración",
  team_description: "Reunimos a expertos de la industria, tecnólogos y emprendedores para construir una empresa que está resolviendo uno de los mayores problemas de la industria musical.",
  careers_page: {
    main_title: "Únete al equipo.",
    interested_in_joining: "¿Quieres unirte?",
    interested_description: "Siempre estamos buscando talento excepcional para nuestro equipo. Envíanos tu curriculum y te contactaremos cuando haya una posición adecuada para tu perfil.",
    work_with_us: {
      title: 'Trabaja con nosotros',
      description1: '<b> TREBEL es el servicio de música con crecimiento más rápido en Latinoamérica </b> y el primer soonicorn de entretenimiento en la región. ',
      description2: "<b> TREBEL no es un servicio de música más, </b> TREBEL es la única app en la que los usuarios no pagan y aún así pueden descargar canciones, escuchar sin internet, y apoyar a los artistas a través de un modelo de negocio patrocinado por marcas.",
      btn_text: 'ver empleos'
    },
    remote: {
      heading: 'Priorizamos el trabajo remoto',
      description: "¿Por qué contratar a la mejor persona para el trabajo en un radio de 30 kilómetros cuando puedes contratar a la mejor persona para el trabajo?"
    },
    our_values: {
      title: 'Nuestros Valores',
      description: 'Nuestros valores son la base de todo lo que hacemos en TREBEL.',
      blocks: {
        blockOne: {
          title: "Las personas importan.",
          description: "En TREBEL, hay confianza, respeto y reconocimiento en todos los niveles, y se motiva a tomar riesgos que creen nuevas oportunidades e iniciativas. Aprendemos de nuestros errores y como equipo celebramos la satisfacción del esfuerzo y ambición."
        },
        blockTwo: {
          title: "Siempre sigue avanzando.",
          description: "Tenemos grandes metas. Bájale el volumen al ruido, a los que sólo dicen “no” y a los que no entienden. Si tu convicción o tolerancia al riesgo nunca fue puesta a prueba, nuestra startup va a retar todo lo que piensas saber. Mantengamos el curso, y lograremos lo que otros piensan que es imposible."
        },
        blockThree: {
          title: "Busca los retos que son casi imposibles.",
          description: "Si algo es fácil, seguro hay muchas otras personas haciéndolo. Buscamos problemas difíciles de resolver, y los afrontamos con entusiasmo para innovar y demostrar las soluciones que pueden ser."
        },
        blockForth: {
          title: "Diviértete.",
          description: "La vida es corta, en TREBEL deberías amar lo que haces todos los días y tener el balance correcto entre el trabajo y tu vida personal. TREBEL no es un culto, todos debemos disfrutar nuestra vida y a quienes forman parte de ella."
        }
      }
    }
  },
  partners_page: {
    main_title: "SÚBELE EL VOLUMEN A TU NEGOCIO",
    desc_title: 'Cada día, millones de usuarios hacen a TREBEL parte de su rutina, por eso ganamos los reconocimientos “Favorita del Público”, “Esencial Diario” y “Mejor App” en los premios de la Play Store de Google.',
    desc_description: "Aprovecha el poder de la música para conectar con miles de fans y alcanzar los objetivos de tu negocio con resultados garantizados y escalables.",
    crea: 'CREA CONEXIONES <br> CON LA AUDIENCIA <br>MÁS APASIONADA',
    crea_description_1: 'Nuestros usuarios pasan horas al día en TREBEL: descubren música nueva, crean playlists, disfrutan de su tiempo libre y comparten sus canciones favoritas con amigos.',
    crea_description_2: 'Si quieres conocer más sobre nuestra vibrante comunidad de fans y cómo podemos personalizar tu estrategia de marketing para conectar con tu potencial audiencia, conecta con nuestro equipo.',
    btn_text: 'Anúnciate con nosotros ',
    crea_btn_text: 'Descubre más',
    dale_title: 'DALE PLAY A RESULTADOS GARANTIZADOS',
    dale_description: "Publicita tu marca con nuestras soluciones de anuncios inmersivos, full-screen y en video, así como experiencias transmedia que te ayudarán a conectar con tus audiencias y alcanzar los objetivos de tu estrategia.",
    band_title: "ÚNETE A LA BANDA",
    band_description: "Inspírate con estos casos  de éxito de algunos  de nuestros partners.",
    band_btn_text: "¡Conversemos!",
    mejor_title: 'LA MEJOR PLAYLIST DEL MUNDO',
    mejor_description: 'La publicidad de tu marca convive con el mejor contenido musical del mundo, gracias a nuestras alianzas con las disqueras más importantes de la industria.',
    toma_title: '¡TOMA EL TOUR!',
    toma_description_1: '¿Es tu primera vez anunciando con nosotros? Vive la experiencia de TREBEL for BUSINESS desde la perspectiva del usuario y descubre cómo se vería tu marca dentro de nuestra plataforma.',
    toma_description_2: 'Ponte en contacto con nosotros para solicitar un tour y una consultoría personalizada de parte de uno de nuestros asesores.',
    toma_btn: 'Mándanos un mensaje',
    partner_footer_btn: '¡Empezar!',
    icons: {
      safe: {
        title: "Brand safe",
        description: "Asocia tu marca con el mejor contenido del mundo."
      },
      view: {
        title: "Top Viewability",
        description: "Video de alto viewability y experiencia inmersiva de alto engagement."
      },
      humans: {
        title: "Real Humans",
        description: "Usuarios reales, libre de fraude, garantizando que cada impresión cuente."
      },
      stream: {
        title: "In-stream video",
        description: "100% in-stream Integra tu mensaje a la experiencia."
      },
      sound: {
        title: "Sound on",
        description: "100% sonido prendido Maximiza el impacto de tus campañas."
      },
      skip: {
        title: "Unskippable",
        description: "100% unskippable Optimizado para vistas completas."
      }
    },
    our_audience: "Nuestra audiencia está esperando a conocerte",
    our_audience_desc: "Nuestra misión es proveer a millones de usuarios acceso a la más grande librería de contenido, sin costo, gracias al patrocinio de las marcas. Nosotros podemos ayudarte a asegurar que tu mensaje se entrega a la audiencia correcta en medio de una experiencia única en el mundo.",
    have_content: "¿Tienes música o podcasts?",
    have_content_desc: "Si eres dueño de una disquera, productor de podcasts o una compañía de distribución de música, nos encantaría sumarte a nuestra creciente lista de partners para ayudarte a crecer tu audiencia.",
    have_content_btn: "ESCRÍBENOS",
    ready_to_join: "¿Estás listo para sumarte a nosotros?",
    join_btn_text: "CONTÁCTANOS"
  },
  send_us_resume: "ENVÍANOS TU CURRICULUM",
  downloadPage :'Escucha tu música con o sin conexión.',
  meta: {
    main: {
      title: "Aplicación gratuita para descargar música para Android e iOS | TREBEL Music",
      description: "Escucha millones de canciones gratis con la aplicación TREBEL para descargar música para Android e iOS. ¡Descarga hoy para mp3 móvil y música para empezar a escuchar sin conexión!"
    },
    about: {
      title: "Acerca de TREBEL Music",
      description: "TREBEL Music es un líder global en acceso gratuito y legal a música sin internet o pago requerido para Android e iOS. Conoce nuestra historia y a nuestro equipo."
    },
    career: {
      title: "Carreras en TREBEL Music: Ofertas de trabajo",
      description: "La misión de TREBEL es crear una experiencia musical sin conexión extraordinaria y gratuita para usuarios de todo el mundo. Para llegar allí, necesitamos tu talento. ¿Estás dentro?"
    },
    partners: {
      title: "Únete a nuestros millones de fanáticos de la música como socio de TREBEL",
      description: "Sé parte de la experiencia musical extraordinaria de TREBEL para millones de usuarios en todo el mundo. ¡Únete hoy como uno de nuestros socios de marca y contenido!"
    }
  },
  on_our_playlist: "En su lista de reproducción...",
  streaks: {
    title: `Cómo la música puede elevar tu ánimo, salud e inteligencia`,
    march5: "MARZO 5, 2024",
    minRead: "LECTURA 5 MIN",
    wellness: "BIENESTAR",
    content: {
      intro: `¿Alguna vez te has preguntado por qué tu canción favorita tiene un impacto tan poderoso en tu ánimo? ¡La ciencia tiene la respuesta! En pocas palabras, escuchar música todos los días no es solo una rutina, es una dosis de salud, un levanta ánimos, y hasta un “booster” cerebral.`,
      intro2: `Hablando de boosters, escuchando música a diario en TREBEL puedes ganar boosters para descargar tus canciones más rápido.`,
      qrTitle: 'Escanea el QRcon tu teléfonoy comienza una racha',
      qrButton: '¡Comienza una racha!'
    },
    list: {
      title: "La ciencia lo respalda",
      subtitle: "En 2009, arqueólogos encontraron una flauta de 40,000 años de antigüedad, el instrumento musical más antiguo conocido, demostrando que nuestra relación con la música tiene raíces profundas. Pero, ¿cómo nos afecta hoy en día?",
      elements: {
        first: {
          title: '1. La música nos conecta',
          sub1: "¿Alguna vez has sentido esa conexión especial en un concierto? ¡Es neta! La música nos une socialmente, desde himnos en eventos deportivos hasta mandarle canciones de amor a tu ligue.",
          sub2: "Incluso, algunos científicos evolutivos sugieren que esto se originó desde que nuestros ancestros vivían en los árboles y “cantaban” para crear lazos sociales y formar sus tribus."
        },
        second: {
          title: "2. La música nos hace más felices",
          sub1: "La música puede cambiar la química de tu cerebro: provoca placer, reduce el estrés y fomenta conexiones. También es una excelente herramienta para regular emociones y procesar sentimientos.",
          sub2: "¿Te sientes ansioso? Estudios demuestran que la música puede calmar los nervios, ayudándonos a centrarnos y vivir el momento."
        },
        third: {
          title: "3. La música es gasolina para tu cuerpo",
          sub1: "La música hace que tu corazón lata más fuerte, ¡para bien! Puede alterar tu ritmo cardíaco, presión arterial y frecuencia respiratoria. Si te diriges al gym, escuchar tu playlist favorita mejora tu ánimo, eficiencia y resistencia.",
          sub2: "Además, ¿bailar tus canciones favoritas? ¡Eso es una sesión de cardio disfrazada!"
        },
        forth: {
          title: "4. La Música es alimento para tu cerebro",
          sub1: "¿Quieres potenciar tu cerebro? Médicos en Johns Hopkins dicen que escuchar música hace el truco, activando áreas cerebrales vistas en resonancias magnéticas.",
          sub2: "Las melodías clásicas no solo te hacen sentir elegante, también mejoran la memoria. Ayudan a recordar palabras y realizar tareas más rápido. ¿No te gusta la música clásica? Busca canciones con tambores y percusiones para obtener el mismo efecto."
        }
      }
    },
    takeaway: {
      title: "En resúmen",
      sub1: "En resumen, la música es un superpoder que impulsa la memoria, aligera tu ánimo, reduce la ansiedad y la depresión, combate la fatiga, mejora la respuesta al dolor y potencia tu entrenamiento.",
      sub2: "Si quieres maximizar estos beneficios, comprométete a completar una racha en TREBEL. No solo obtendrás las ventajas integradas de escuchar música todos los días, sino que desbloquearás recompensas como boosters para acelerar tus descargas."
    }
  },
  artists: {
    headerTitle: 'HAZ MÁS <span class="pinkBackground">RUIDO</span> <br /> CON TREBEL',
    headerSubtitle: '¿Eres una disquera, distribuidora o productora de contenido auditivo? Aprovecha la oportunidad de ampliar tu audiencia y conectar  <br class="desktop" />  con los millones de usuarios que han convertido a TREBEL en su soundtrack diario.',
    headerButton: 'Conecta con nosotros',
    globalTitle: 'SOMOS EL <span class="pinkBackground">SOUNDTRACK</span> <br class="desktop" /> DE LA GEN Z',
    globalSubtitle: 'Esta generación, caracterizada por su acceso inmediato a la información, su interconectividad <br class="desktop" /> y su sed de autenticidad, encuentra en TREBEL no solo una plataforma musical, sino <br class="desktop" /> un compañero de vida. <br /> <br />La Gen Z utiliza TREBEL como su escape, su inspiración y su forma de expresión. <br class="desktop" />  Ser parte de TREBEL significa conectar con una audiencia que valora la diversidad musical <br class="desktop" /> y que está ávida de descubrir nuevos sonidos y tendencias.',
    globalButton: 'Descubre más',
    seenTitle: "<span class='pinkBackground'>DESTACA</span> ENTRE <br class=\"desktop\" /> LA MULTITUD",
    seenSubtitle: "Con nuestra plataforma, tu música no solo se mezcla—¡destaca! Captura la atención de millones de amantes de la música en todo el mundo. Desde lugares privilegiados en la navegación de nuestra app hasta posiciones en nuestras listas de reproducción seleccionadas, tu música estará siempre al frente, lista para cautivar a los oyentes. <br /><br /> ¡Y eso no es todo! También colaboramos con los artistas en nuestras campañas de marketing, incluyendo anuncios espectaculares que aseguran que tu música sea vista por todos lados." +
        "<br class=\"desktop\" /> <br class=\"desktop\" />Pro-tip: Déjanos ayudarte a crear una estrategia personalizada para conectar con tu audiencia objetivo usando soluciones innovadoras y efectivas.",
    seenButton: 'Descarga nuestro folleto digital para más información',
    downloadPdf: 'Descarga el PDF',
    dashboardTitle: 'PRESENTAMOS <br class="desktop" /> EL <span class="pinkBackground"> ARTIST DASHBOARD</span>',
    dashboardSubtitle: 'Prepárate para una herramienta revolucionaria que te da control total de tu música. <br /> <br />' +
        'Actualiza tus imágenes, crea y comparte playlists, revisa los resultados de tu música y mucho más—Llegando muy pronto a TREBEL.',
    dashboardButton: '¡Obtén acceso anticipado!',
    connectTitle: '<span class="pinkBackground"> CONECTA </span> CON TUS <br class="desktop" /> FANS DESDE EL <br class="desktop" /> TREBEL STUDIO',
    connectSubtitle: "Desde nuestro estudio en el corazón de la CDMX, <br class=\"desktop\" /> producimos contenidos originales que permiten a los <br class=\"desktop\" /> artistas conectar con sus fans y potenciales audiencias. <br /> <br /> " +
        "Ya sea compartiendo sus historias en íntimos podcasts, <br class=\"desktop\" /> mostrando su lado más divertido y espontáneo con <br class=\"desktop\" /> dinámicas y juegos, o destacando la diversidad de voces y <br class=\"desktop\" /> perspectivas en la industria musical.",
    connectButton: 'Quiero estar en TREBEL',
    onTrebelTitle: '<span class="pinkBackground">LO MÁS TOP</span> ESTÁ EN TREBEL',
    onTrebelSubtitle: "En TREBEL, nos enorgullece ofrecer el mejor contenido <br class=\"desktop\" /> musical del mundo, y esto es posible gracias a nuestras <br class=\"desktop\" /> sólidas alianzas con las disqueras más influyentes <br class=\"desktop\" /> de la industria. ¡Solo faltas tú!",
    onTrebelButton: '¡Conversemos!',
    describeTitle: '<span class="fs-52">DISTRIBUYE</span> <br /> <span class="fs-39">TU CONTENIDO </span> <br /><span class="fs-32">EN </span><span class="fs-69">TREBEL</span>',
    describeButton: '¡Empezar!',
  },
  form: {
    nombreCompania: 'Nombre de la compañía *',
    nombreTu: 'Tu nombre *',
    email: 'Email *',
    numeroTelefono: 'Número de teléfono *',
    dejaMessage: 'Deja tu mensaje *',
    button: 'Enviar',
    incorrectEmail: 'El correo electrónico es incorrecto.',
    phoneIncorrect: 'El número de teléfono es incorrecto',
    ola: "Hola",
    excited: '¡Estamos emocionados de saber de ti!',
    gracias: '¡Gracias por enviar la solicitud de contacto! <br/> Nuestros agentes se comunicarán contigo dentro de las siguientes 24 horas.',
    close: 'cerrar'
  }
}
